export const adTypes = {
  inContent: "In content",
  inContent1: "In content 1",
  inContent2: "In content 2",
  inContent3: "In content 3",
  inContent4: "In content 4",
  inContent5: "In content 5",
  inContent6: "In content 6",
  inContent7: "In content 7",
  inContent8: "In content 8",
  inContent9: "In content 9",
  inContent10: "In content 10",
  firstImpression: "First impression",
  outOfFrame: "Out of frame",
};

export type AdType =
  | "firstImpression"
  | "inContent"
  | "inContent1"
  | "inContent2"
  | "inContent3"
  | "inContent4"
  | "inContent5"
  | "inContent6"
  | "inContent7"
  | "inContent8"
  | "inContent9"
  | "inContent10"
  | "outOfFrame";

export type Advertiser = {
  key: string;
  id: string;
  tagType: string;
  tagScript: string;
  tagBackfill: string;
  active: any;
  publisher: string;
  domain: string;
  wid: number;
  pub_id: number;
  domain_id: number;
  pub_key: string;
  size: string;
  placement: string;
  adType: AdType | null;
  location: any;
  skip: boolean;
  refresh: number | null;
  adTimer: number | null;
  providers: Provider[];
  timeout: number | null;
  bidfloor: number | null;
  platform: string | null;
  sticky: boolean;
};

export type Provider = {
  providerName: string | null;
  providerFields: { [fieldName: string]: string };
  id: string;
};

export type Setup = {
  firstImpression: Advertiser | undefined;
  inContent: Advertiser | undefined;
  outOfFrame: Advertiser | undefined;
};

export type Mode = "new" | "edit" | "duplicate";

export type Test = {
  id: string;
  key: string;
  pub_id: number;
  domain_id: string;
  domain_name: string;
  pub_name: string;
  // pub_key: string;
  wid: number;
  active: boolean;
  splitA: number;
  splitB: number;
  userChoice: UserChoice | undefined;
  contentType: string | undefined | null;
  unitType: number | undefined | null;
  itemType: string | undefined | null;
};

export type Publisher = {
  pub_id: number;
  pub_name: string;
  public_key: string;
  is_active: boolean;
  publisher_date_created: Date;
  pub_contact_name: string;
  pub_email: string;
  contract_type: number;
  cpm: number;
  location: string;
  terms_of_payment_in_days: number;
  dashboard_popup_message_url: string;
  InCorporated: boolean;
  websites: string;
  publisher_cpm: any;
  cpm_start_date: any;
  publisher_serving_fee: any;
  revenue_source: string;
  display_revenue_by_domain: boolean;
};

export type ExtendedPubDetails = {
  pub_id: number;
  public_key: string;
  pub_name: string;
  pub_contact_name: string;
  pub_email: string;
  contract_type: number;
  cpm: number;
  location: string;
  terms_of_payment_in_days: number;
  dashboard_popup_message_url: string;
  InCorporated: boolean;
};
export type UserChoice = "contentType" | "unitType";

export type Scheduler = {
  pub_id: number;
  domain_id: number;
  platforms: string[];
  rate: string;
  username: string;
  widget_id: number | null;
  platform: any;
};

export type HideUnit = {
  pub_id: number;
  pub_key: string;
  domain_id: number;
  widget_id: number | null;
  url: string;
};

export type Domain = {
  did_allow_desktop: boolean;
  did_allow_mobile: boolean;
  did_allow_tablet: boolean;
  domain_id: number;
  domain_public_key: number;
  in_content: boolean;
  in_image: boolean;
  name: string;
  pid_allow_desktop: boolean;
  pid_allow_mobile: boolean;
  pid_allow_tablet: boolean;
  pub_name: string;
  public_key: string;
  publisher_date_created: Date;
  widgets?: any;
  widgetsArray?:number[]
};

export type Widget = {
  is_in_content: boolean;
  is_mobile: boolean;
  is_tablet: boolean;
  item_types: string;
  layout_types: string;
  ref_pid: number;
  widget_id: number;
};

export interface DefaultAiCategoriesType {
  id: string;
  category: string;
}

export type isPlatform = "is_in_content" | "is_mobile" | "is_tablet";

export type ProviderDetailes = {
  provider_id: number;
  name: string;
  contact_name: string;
  is_api: any;
  day_date: Date;
  contact_email: any;
  report_method: string;
  username: string;
  password: string;
  rows_to_skip: number;
  tag_id_index: number;
  revenue_index: number;
  imps_index: number;
  ad_requests_index: number;
  modified_by: string;
  revshare_percentage: number;
  scheduler?: any;
};

export type LogFilter = {
  startDate?: string;
  endDate?: string;
  userEmail?: string;
  actionType?: string;
  action_context?: string;
};
