import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Dashboard from "../../pages/Dashboard/Dashboard";
import DomainSelection from "../../pages/DomainSelection/DomainSelection";
import Login from "../../pages/Login/Login";
import { AppState } from "../../store/store";
import Loading from "../Loading/Loading";
import SocialUnitSelect from "../../pages/SocialUnitSelect/SocialUnitSelect";
import History from "../../pages/SocialUnitSelect/History";
import { Layout } from "antd";
import SideBar from "../SideBar/SideBar";
import AdsTxtManager from "../../pages/AdsTxtManager/AdsTxtManager";
import TopHeader from "../Header/Header";
import "./styles.scss";
import EmbedScaner from "../../pages/EmbedScaner/EmbedScaner";
import Advertisers from "../../pages/Advertisers/Advertisers";
import { getDomainsByPubId } from "../../api/services/Domains";
import ExtensionUpdate from "../../pages/ExtensionUpdate/ExtensionUpdate";
import Publishers from "../../pages/Publishers/Publishers";
import ActivePublishers from "../../pages/Publishers/ActivePublishers";
import Scheduler from "../../pages/SocialUnitSelect/Scheduler";
import Providers from "../../pages/Providers/Providers";
import Adjustments from "../../pages/Adjustments/Adjustments";
import Revenue from "../../pages/Revenue/Revenue";
import DemoCreator from "../../pages/DemoCreator/DemoCreator";
import HideUnit from "../../pages/HideUnit/HideUnit";
import { getPermission } from "../../api/services/User";
import { checkPermissionOk } from "../../utils/utils";

const { Content } = Layout;

function AppRoutes() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isValidateToken, setIsValidateToken] = useState<boolean>(false);
  const roles = useSelector((state: AppState) => state.roles);
  const permissions = useSelector((state: AppState) => state.permissions);
  const publisher = useSelector((state: AppState) => state.publisher);

  const { user } = useAuth0();
  const dispatch = useDispatch();

  const checkPermission = (permission: string) => {
    return permissions && checkPermissionOk(permission, permissions);
  };

  const children = (
    <>
      <Route
        path="/"
        element={
          <Navigate
            to={roles.includes("user") ? "/dashboard" : "/domain-selection"}
          />
        }
      />

      {roles[0] != "user" && !publisher.key && (
        <>
          <Route
            path={"/dashboard"}
            element={<Navigate to={"/domain-selection"} />}
          />
          <Route
            path={"/unit-select/explore"}
            element={<Navigate to={"/domain-selection"} />}
          />
          <Route
            path={"/unit-select/management"}
            element={<Navigate to={"/domain-selection"} />}
          />
        </>
      )}

      <Route path="/ads-txt" element={<AdsTxtManager />} />
      {checkPermission("read:dashboard") && (
        <Route path="/dashboard" element={<Dashboard />} />
      )}
      {checkPermission("read:unitSelect") && (
        <Route path="/unit-select/explore" element={<SocialUnitSelect />} />
      )}
      {checkPermission("read:unitSelect") && (
        <Route path="/unit-select/management" element={<History />} />
      )}

      {checkPermission("read:domainSelection") && (
        <Route path="/domain-selection" element={<DomainSelection />} />
      )}

      {checkPermission("read:scheduler") && (
        <Route path="/scheduler" element={<Scheduler />} />
      )}
      <Route path="/embed-scanner" element={<EmbedScaner />} />
      {checkPermission("read:advertisers") && (
        <Route path="/advertisers" element={<Advertisers />} />
      )}
      {checkPermission("read:extensionUpdate") && (
        <Route path="/extension-update" element={<ExtensionUpdate />} />
      )}
      {checkPermission("read:publishers") && (
        <Route path="/publishers" element={<Publishers />} />
      )}
      {checkPermission("read:activepublishers") && (
        <Route path="/active-publishers" element={<ActivePublishers />} />
      )}
      {checkPermission("read:providers") && (
        <Route path="/providers/management" element={<Providers />} />
      )}
      {checkPermission("read:adjustments") && (
        <Route path="/adjustments" element={<Adjustments />} />
      )}
      {checkPermission("read:revenue") && (
        <Route path="/revenue" element={<Revenue />} />
      )}

      {checkPermission("read:demoCreator") && (
        <Route path="/demo-creator" element={<DemoCreator />} />
      )}

      {checkPermission("read:hideUnit") && (
        <Route path="/hide-unit" element={<HideUnit />} />
      )}

      <Route path="/*" element={<Navigate to={"/dashboard"} />} />
    </>
  );

  useEffect(() => {
    async function handleVisibilityChange() {
      try {
        localStorage.removeItem("accessToken");
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://dev-ovcxuhr3lpxgrden.us.auth0.com/api/v2/",
          },
        });
        localStorage.setItem("accessToken", accessToken);
        setIsValidateToken(true);
      } catch (err) {
        setIsValidateToken(false);
        dispatch({ type: "SET_SHOW_LOGOUT_MODAL", payload: true });
      }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    async function fetchToken() {
      if (!isLoading && isAuthenticated) {
        try {
          localStorage.removeItem("accessToken");
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: "https://dev-ovcxuhr3lpxgrden.us.auth0.com/api/v2/",
            },
          });
          localStorage.setItem("accessToken", accessToken);
          setIsValidateToken(true);
        } catch (err) {
          setIsValidateToken(false);
          dispatch({ type: "SET_SHOW_LOGOUT_MODAL", payload: true });
        }
      }
    }
    fetchToken();
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (user && isValidateToken) {
      setPermission();
      setGlobalPublisher();
    }
  }, [user, isValidateToken]);

  const setGlobalPublisher = async () => {
    if (user) {
      let roles = user["dev-ovcxuhr3lpxgrden.us.auth0.com/roles"];
      if (!roles.includes("admin")) {
        let { pub_id } =
          user["dev-ovcxuhr3lpxgrden.us.auth0.com/user_metadata"];
        let domainIds = await getDomainsByPubId(pub_id);
        let key = domainIds[0].pub_id;
        let public_key = domainIds[0].public_key;
        let pub_name = domainIds[0].name;
        let data = { key, public_key, pub_name };
        dispatch({ type: "SET_PUBLISHER", payload: data });
      }
    }
  };

  const setPermission = async () => {
    if (!permissions) {
      let data = await getPermission();
      dispatch({ type: "SET_PERMISSIONS", payload: data });
    }
  };

  if (isLoading) return <Loading />;
  else if (roles.length == 0 && isAuthenticated) return <Loading />;
  else if (!isLoading && !isAuthenticated) return <Login />;
  else if (isValidateToken && !permissions) return <Loading />;
  else if (isValidateToken && permissions)
    return (
      <Router>
        <Layout className="layout-wrapper">
          <TopHeader />
          <Layout>
            <SideBar />
            <Content className="content">
              <Routes>{children}</Routes>
            </Content>
          </Layout>
        </Layout>
      </Router>
    );
  else return <Loading />;
}

export default AppRoutes;
